import React from 'react';

const IconImage = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path d="M0 0h24v24H0z" />
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}>
      <path d="M19 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2z" />
      <path d="M8.5 10a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM21 15l-5-5L5 21" />
    </g>
  </svg>
);

export default IconImage;
