import React from 'react';

const IconBookmarkFilled = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconBookmarkFilled;
