import React from 'react';

const IconLogOut = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}>
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4M16 17l5-5-5-5M21 12H9" />
    </g>
  </svg>
);

export default IconLogOut;
