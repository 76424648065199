import React from 'react';

const IconBookmark = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconBookmark;
