import React from 'react';

const IconDirectDown = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      stroke="currentColor"
      strokeWidth={2}
      d="M6 9l6 6 6-6"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconDirectDown;
