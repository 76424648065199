import React from 'react';

const IconArrowLeft = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      strokeWidth={2}>
      <path d="M19 12H5M12 19l-7-7 7-7" />
    </g>
  </svg>
);

export default IconArrowLeft;
