import React from 'react';

const IconNewTab = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      strokeWidth={2}>
      <path d="M7 17L17 7M7 7h10v10" />
    </g>
  </svg>
);

export default IconNewTab;
