import React from 'react';

const IconCheck = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M20 7L9 18l-5-5"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default IconCheck;
