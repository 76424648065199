import React from 'react';

const IconRefresh = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M23 4v6h-6M1 20v-6h6" />
      <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15" />
    </g>
  </svg>
);

export default IconRefresh;
