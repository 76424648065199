import React from 'react';

const IconArchive = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}>
      <path d="M21 8v13H3V8M23 3H1v5h22zM10 12h4" />
    </g>
  </svg>
);

export default IconArchive;
