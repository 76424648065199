import React from 'react';

const IconFlag = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.15}>
      <path
        clipRule="evenodd"
        d="M5 14.4s.813-.8 3.25-.8 4.063 1.6 6.5 1.6 3.25-.8 3.25-.8V4.8s-.813.8-3.25.8S10.687 4 8.25 4 5 4.8 5 4.8z"
      />
      <path d="M5 20v-5.6" />
    </g>
  </svg>
);

export default IconFlag;
