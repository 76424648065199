import React from 'react';

const IconBook = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2V3zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7V3z" />
    </g>
  </svg>
);

export default IconBook;
