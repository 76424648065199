import React from 'react';

const IconDirectRight = props => (
  <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      d="M9 18l6-6-6-6"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default IconDirectRight;
