import React from 'react';

const IconAdd = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M12 5v14M5 12h14" />
    </g>
  </svg>
);

export default IconAdd;
