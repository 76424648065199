import React from 'react';

const IconFeed = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      transform="translate(4 4)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M0 7a9 9 0 0 1 9 9M0 0c8.837 0 16 7.163 16 16" />
      <circle cx={1} cy={15} r={1} />
    </g>
  </svg>
);

export default IconFeed;
