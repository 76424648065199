import React from 'react';

const IconMoreHorizontal = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g
      transform="translate(4 11)"
      stroke="currentColor"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round">
      <circle cx={8} cy={1} r={1} />
      <circle cx={15} cy={1} r={1} />
      <circle cx={1} cy={1} r={1} />
    </g>
  </svg>
);

export default IconMoreHorizontal;
