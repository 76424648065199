// @flow

import React from 'react';

type Props = {
  letter: 'p' | 'o' | 'k' | 'e' | 't',
};

const svg = {
  p: (
    <svg
      height="25"
      viewBox="0 0 20 25"
      width="20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m.31291754 74.8346691v-19.0272054c0-1.4821918 1.17915127-2.6541573 2.67043082-2.6541573h7.24831224c5.7917136 0 9.2944865 3.4124879 9.2944865 8.3416371 0 5.6530103-4.3697959 8.5484546-9.81470032 8.5484546h-4.0576676v4.791271c0 1.4821917-1.17915127 2.6541572-2.67043082 2.6541572s-2.67043082-1.1719655-2.67043082-2.6541572zm5.34086164-9.5136027h4.2310722c2.67043082 0 4.23107222-1.5856005 4.23107222-3.653775v-.0689391c0-2.3784007-1.6646842-3.653775-4.33511498-3.653775h-4.12702944z"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(0 -53)"
      />
    </svg>
  ),
  o: (
    <svg
      height="25"
      viewBox="0 0 26 25"
      width="26"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m45.877311 24.9825525c-7.4910786 0-12.8666212-5.5496016-12.8666212-12.409047 0-6.92838461 5.4449044-12.54692534 12.9359831-12.54692534 7.4910786 0 12.8666212 5.54960158 12.8666212 12.40904704 0 6.9283845-5.4449044 12.5469253-12.9359831 12.5469253zm.0693619-4.8946797c4.300434 0 7.2829931-3.3780183 7.2829931-7.5143673v-.0689392c0-4.13634898-3.0519209-7.58330648-7.352355-7.58330648-4.300434 0-7.2829931 3.37801835-7.2829931 7.51436738v.0689391c0 4.136349 3.0519209 7.5833065 7.352355 7.5833065z"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(-33)"
      />
    </svg>
  ),
  k: (
    <svg
      height="25"
      viewBox="0 0 21 25"
      width="21"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m74.5615107 60.2098449v-19.2340229c0-1.4821917 1.1791513-2.6541573 2.6704308-2.6541573 1.4912796 0 2.6704309 1.1719656 2.6704309 2.6541573v8.1003501l9.8493812-9.7204201c.6589374-.6549219 1.3178749-1.0340873 2.2542597-1.0340873 1.4912796 0 2.4623453 1.137496 2.4623453 2.4473399 0 .8272698-.3814901 1.4477221-.9710657 1.9992353l-6.8321412 6.3424018 7.4563977 9.3067853c.4161711.5170436.7282994 1.0685568.7282994 1.861357 0 1.4821917-1.1444704 2.5852181-2.7051118 2.5852181-1.0404276 0-1.6646841-.5170436-2.2542598-1.2753742l-6.9361839-9.0310287-3.0519209 2.8265052v4.8257405c0 1.4821917-1.1791513 2.6541572-2.6704309 2.6541572-1.4912795 0-2.6704308-1.1719655-2.6704308-2.6541572z"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(-74 -38)"
      />
    </svg>
  ),
  e: (
    <svg
      height="25"
      viewBox="0 0 19 25"
      width="19"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m117.782352 39.5061651c-1.49128 0-2.670431-1.1719655-2.670431-2.6541573v-18.8203879c0-1.4821917 1.179151-2.6541573 2.670431-2.6541573h13.456197c1.90745 0 2.392983 1.0685568 2.392983 2.3784007 0 1.3098438-1.075108 2.3439311-2.392983 2.3439311h-10.820447v4.8946796h9.259805c1.317875 0 2.392984 1.0685569 2.392984 2.3784007 0 1.3098439-1.075109 2.3439311-2.392984 2.3439311h-9.259805v5.0670275h10.993851c1.317875 0 2.392984 1.0685569 2.392984 2.3784007 0 1.3098439-1.075109 2.3439311-2.392984 2.3439311z"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(-115 -15)"
      />
    </svg>
  ),
  t: (
    <svg
      height="25"
      viewBox="0 0 22 25"
      width="22"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m167.110461 48.9861217v-16.786683h-5.132777c-1.352555 0-2.462345-1.1030264-2.462345-2.4473399 0-1.3443134 1.10979-2.4473398 2.462345-2.4473398h15.606414c1.352556 0 2.462346 1.1030264 2.462346 2.4473398 0 1.3443135-1.10979 2.4473399-2.462346 2.4473399h-5.132776v16.786683c0 1.4821917-1.179151 2.6541573-2.670431 2.6541573-1.491279 0-2.67043-1.1719656-2.67043-2.6541573z"
        fill="currentColor"
        fillRule="evenodd"
        transform="translate(-159 -27)"
      />
    </svg>
  ),
};

const HomePoketoLetter = (props: Props) => svg[props.letter];

export default HomePoketoLetter;
