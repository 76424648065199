import React from 'react';

const IconPoketo = props => (
  <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <rect fill="currentColor" height={24} rx={3.52} width={24} />
      <path
        d="M8.971 18.58L6.297 7.856a1.53 1.53 0 0 1 1.123-1.87l4.06-1.012c3.245-.809 5.687.626 6.38 3.404l.01.039c.784 3.147-1.257 5.389-4.307 6.15l-2.273.566.673 2.7c.208.836-.287 1.661-1.123 1.87s-1.66-.288-1.869-1.123zm1.655-6.108l2.37-.591c1.496-.373 2.148-1.485 1.857-2.65l-.01-.04c-.334-1.34-1.446-1.826-2.942-1.453l-2.311.576z"
        fill="#fff"
      />
    </g>
  </svg>
);

export default IconPoketo;
